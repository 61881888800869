import { ActivityTypePatchDTO } from 'models/ActivityTypePatch'
import { RankingGroupDTO } from 'models/RankingGroup'
import { TotalDataDTO } from 'models/TotalData'

export const benefitsMock: string[] = [
  'co2',
  'absenteeism',
  'traffic',
  'ptt',
  'sustanaible',
]

export const actionsMock: string[] = [
  'incentives',
  'carpooling',
  'mobility',
  'remote',
  'transport',
  'parking',
  'electricCar',
  'bus',
  'communicate',
]

export const userPerGroupMock = [
  {
    uuid: '4ee6b624-983a-441d-a098-bc5e589e5723',
    name: 'ULMA Agrícola',
    new_users: 9,
  },
  {
    uuid: '46c87d02-fa9a-4fd9-b230-61dae46cf4af',
    name: 'ULMA Architectural Solutions',
    new_users: 40,
  },
  {
    uuid: '87e3302e-1fac-4abc-9ccd-5d733afa4a86',
    name: 'ULMA Construction',
    new_users: 147,
  },
  {
    uuid: 'b8e5b5ea-92cf-4434-bab4-10948509949d',
    name: 'ULMA Conveyor Components',
    new_users: 4,
  },
  {
    uuid: '6d70af82-0e55-41d1-90aa-35882bc9fffc',
    name: 'ULMA Embedded Solutions',
    new_users: 8,
  },
  {
    uuid: '34640f6b-c55c-4760-b8f2-d06d40b6798d',
    name: 'ULMA Forged Solutions',
    new_users: 84,
  },
  {
    uuid: '39a14d54-c3fe-40fa-95da-b5b8b132debf',
    name: 'ULMA Handling Systems',
    new_users: 89,
  },
  {
    uuid: '556f21eb-f85a-4810-9683-8df0fe40deae',
    name: 'ULMA Lifting Solutions',
    new_users: 37,
  },
  {
    uuid: 'bebd8df9-10ed-4e98-8dcd-753be5725b1b',
    name: 'ULMA Packaging',
    new_users: 471,
  },
  {
    uuid: '06a4b866-d9c6-48ec-a593-623a3232e191',
    name: 'ULMA Sustapen berriak / Nuevas promociones',
    new_users: 10,
  },
  {
    uuid: '32eba13d-e789-4303-950f-01a496ebfbb2',
    name: 'ULMA Taldea',
    new_users: 14,
  },
]

export const rankingMock: RankingGroupDTO[] = [
  {
    uuid: 'bebd8df9-10ed-4e98-8dcd-753be5725b1b',
    name: 'ULMA Packaging',
    distance: 1063901,
    co2: 124628,
    ciclos: 1410598,
    total_activities: 175697,
    active_users: 360,
  },
  {
    uuid: '87e3302e-1fac-4abc-9ccd-5d733afa4a86',
    name: 'ULMA Construction',
    distance: 542402,
    co2: 54924,
    ciclos: 506808,
    total_activities: 60025,
    active_users: 116,
  },
  {
    uuid: '34640f6b-c55c-4760-b8f2-d06d40b6798d',
    name: 'ULMA Forged Solutions',
    distance: 159154,
    co2: 20937,
    ciclos: 240045,
    total_activities: 25221,
    active_users: 66,
  },
  {
    uuid: '46c87d02-fa9a-4fd9-b230-61dae46cf4af',
    name: 'ULMA Architectural Solutions',
    distance: 130254,
    co2: 17019,
    ciclos: 215054,
    total_activities: 12552,
    active_users: 34,
  },
  {
    uuid: '39a14d54-c3fe-40fa-95da-b5b8b132debf',
    name: 'ULMA Handling Systems',
    distance: 246582,
    co2: 24612,
    ciclos: 191130,
    total_activities: 19404,
    active_users: 59,
  },
  {
    uuid: '556f21eb-f85a-4810-9683-8df0fe40deae',
    name: 'ULMA Lifting Solutions',
    distance: 55972,
    co2: 7011,
    ciclos: 141671,
    total_activities: 23259,
    active_users: 26,
  },
  {
    uuid: '32eba13d-e789-4303-950f-01a496ebfbb2',
    name: 'ULMA Taldea',
    distance: 12374,
    co2: 1798,
    ciclos: 24312,
    total_activities: 1882,
    active_users: 8,
  },
  {
    uuid: '6d70af82-0e55-41d1-90aa-35882bc9fffc',
    name: 'ULMA Embedded Solutions',
    distance: 3992,
    co2: 404,
    ciclos: 11214,
    total_activities: 2504,
    active_users: 6,
  },
  {
    uuid: '4ee6b624-983a-441d-a098-bc5e589e5723',
    name: 'ULMA Agrícola',
    distance: 3493,
    co2: 515,
    ciclos: 5933,
    total_activities: 756,
    active_users: 3,
  },
  {
    uuid: 'b8e5b5ea-92cf-4434-bab4-10948509949d',
    name: 'ULMA Conveyor Components',
    distance: 9556,
    co2: 785,
    ciclos: 3522,
    total_activities: 487,
    active_users: 4,
  },
  {
    uuid: '06a4b866-d9c6-48ec-a593-623a3232e191',
    name: 'ULMA Sustapen berriak / Nuevas promociones',
    distance: 7184,
    co2: 604,
    ciclos: 1811,
    total_activities: 174,
    active_users: 4,
  },
]

export const totalDataMock: TotalDataDTO = {
  kms: 2234682,
  co2: 277371,
  money_saving: 415908,
  ciclos_total: 2752023,
  active_users: 686,
  total_activities: 321952,
  handled_users: 913,
  new_users: 913,
}

export const totalDataLastYearMock: TotalDataDTO = {
  kms: 550307,
  co2: 61655,
  money_saving: 104204,
  ciclos_total: 503221,
  active_users: 369,
  total_activities: 61317,
  handled_users: 913,
  new_users: 106,
}

export const activitiesByTypeMock: ActivityTypePatchDTO[] = [
  {
    kms: 173912,
    co2: 12869,
    money_saving: 32410,
    ciclos: 109029,
    active_users: 189,
    total_activities: 23022,
    activity_type: 'Bus Ride',
    activity_type_uuid: '3259878f-19b6-4ced-9319-002685312bf3',
  },
  {
    kms: 791505,
    co2: 135347,
    money_saving: 141446,
    ciclos: 1593596,
    active_users: 443,
    total_activities: 177114,
    activity_type: 'Cycling',
    activity_type_uuid: '792a54b7-abc0-4364-851a-3553258300dd',
  },
  {
    kms: 26890,
    co2: 4544,
    money_saving: 4843,
    ciclos: 26993,
    active_users: 80,
    total_activities: 5184,
    activity_type: 'Electric Bike',
    activity_type_uuid: '494bf222-27a8-4417-b3a1-7dffda1a3bd9',
  },
  {
    kms: 7380,
    co2: 944,
    money_saving: 1457,
    ciclos: 1485,
    active_users: 11,
    total_activities: 197,
    activity_type: 'Electric Car',
    activity_type_uuid: '9399aca7-2f45-4b18-a14b-14c7e319c0fa',
  },
  {
    kms: 3,
    co2: 0,
    money_saving: 0,
    ciclos: 1,
    active_users: 1,
    total_activities: 1,
    activity_type: 'Electric Motorcycle',
    activity_type_uuid: '80669e5b-07b2-47c5-b054-4735ed995545',
  },
  {
    kms: 15306,
    co2: 2586,
    money_saving: 2718,
    ciclos: 15413,
    active_users: 32,
    total_activities: 3366,
    activity_type: 'Electric Scooter',
    activity_type_uuid: '0a7c506e-ceb5-4768-8657-93d984990979',
  },
  {
    kms: 1340,
    co2: 167,
    money_saving: 263,
    ciclos: 223,
    active_users: 4,
    total_activities: 49,
    activity_type: 'Hybrid Car',
    activity_type_uuid: 'd1bad9ef-7b55-4f73-8c53-f246da4899a4',
  },
  {
    kms: 4530,
    co2: 774,
    money_saving: 830,
    ciclos: 11679,
    active_users: 42,
    total_activities: 736,
    activity_type: 'Running',
    activity_type_uuid: '8287ad69-976d-4432-b24d-945993c938c8',
  },
  {
    kms: 1012798,
    co2: 86087,
    money_saving: 199742,
    ciclos: 279472,
    active_users: 228,
    total_activities: 28646,
    activity_type: 'Share Ride',
    activity_type_uuid: 'd960d630-7034-49e4-98e6-cde8e643bdc5',
  },
  {
    kms: 8908,
    co2: 1523,
    money_saving: 1565,
    ciclos: 26003,
    active_users: 28,
    total_activities: 2422,
    activity_type: 'Skating',
    activity_type_uuid: '9bcf8976-4df3-443b-b2d1-b655db75c972',
  },
  {
    kms: 109,
    co2: 15,
    money_saving: 19,
    ciclos: 107,
    active_users: 6,
    total_activities: 24,
    activity_type: 'Subway Ride',
    activity_type_uuid: '04c9ab3d-a713-4e73-ae75-50fbdd4a1791',
  },
  {
    kms: 12456,
    co2: 1806,
    money_saving: 2447,
    ciclos: 7308,
    active_users: 24,
    total_activities: 463,
    activity_type: 'Train Ride',
    activity_type_uuid: '94ad1cc6-6fe9-4195-b88f-94bf9d4f5b07',
  },
  {
    kms: 179582,
    co2: 30708,
    money_saving: 28169,
    ciclos: 680727,
    active_users: 344,
    total_activities: 80730,
    activity_type: 'Walking',
    activity_type_uuid: 'e09d610a-d165-4dd8-ba1b-ecf3d9937917',
  },
]
